import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import HeroImage from '../../images/use-cases/heroes/merchants.png';
import PageComponent from '../../components/use-cases/PageComponent';


/*
 * Constants
 */
const features = [
    {
        key: 'dynamicRouting',
        logoImg: <StaticImage src="../../images/use-cases/features/dynamic-routing.png" placeholder="none" alt="" />,
        title: <Trans>Negotiate your processing rates.</Trans>,
        name: <Trans>Dynamic Routing</Trans>,
        content: (
            <Trans>
                We know processing rates can add up. By enabling instant access to any financial institution, we can
                help you negotiate your terms, or even provide you with better alternatives.
            </Trans>
        ),
        link: {
            url: '/product/dynamic-routing',
            text: <Trans>Discover Dynamic Routing</Trans>,
        },
    },
    {
        key: 'processing',
        logoImg: <StaticImage src="../../images/use-cases/features/processing.png" placeholder="none" alt="" />,
        title: <Trans>Boost your conversion rates.</Trans>,
        name: <Trans>Processing</Trans>,
        content: (
            <Trans>
                When selling at a global scale, merchants face the challenge of providing several payment methods. In
                specific markets, local alternative payment methods are the key to not lose the customer in the end of
                the sales funnel. We offer access to 50+ APMs, allowing you to give your customers a more familiar
                checkout experience.
            </Trans>
        ),
        link: {
            url: '/product/processing',
            text: <Trans>Discover Processing</Trans>,
        },
    },
    {
        key: 'reconciliation',
        logoImg: <StaticImage src="../../images/use-cases/features/reconciliation.png" placeholder="none" alt="" />,
        title: <Trans>Keep tabs on bookkeeping.</Trans>,
        name: <Trans>Reconciliation</Trans>,
        content: (
            <Trans>
                Managing millions of transactions makes it harder to keep track of settlement schedules, with direct
                impact on working capital. Our platform processes transaction statements from multiple sources and
                provides settlement events for data filtering. This gives you visibility over potential outstanding
                transactions that may need your attention.
            </Trans>
        ),
        link: {
            url: '/product/reconciliation',
            text: <Trans>Discover Reconciliation</Trans>,
        },
    },
    {
        key: 'terminal',
        logoImg: <StaticImage src="../../images/use-cases/features/terminal.png" placeholder="none" alt="" />,
        title: <Trans>Unify your shopping experience online and in-store.</Trans>,
        name: <Trans>Terminal</Trans>,
        content: (
            <Trans>
                Our single integration supports all features both online and in-store. This means you can quickly roll
                out new stores and into new markets, while retaining centralized control over your entire payments
                operation.
            </Trans>
        ),
        link: {
            url: '/product/terminal',
            text: <Trans>Discover Terminal</Trans>,
        },
    },
];


/*
 * Public Elements
 */
const MerchantsUseCasePage = ({data}) => {
    const {t} = useTranslation();

    const title = t('Merchants');
    const quantifier = t('hundreds');
    const subtitle = t(
        'More than 300 merchants are optimizing their payments operations with Switch. See what we can do to help you.',
    );

    const metatags = {
        title: t('The Payment Gateway for Global Expansion - Switch'),
        description: t('Expand to new markets fast with local payment methods. See how we help businesses optimize '
            + 'their payment operations in our case studies.'),
        // eslint-disable-next-line i18next/no-literal-string
        image: '/website/metatags/use-cases-merchants.png',
    };

    return (
        <PageComponent
            data={data}
            image={HeroImage}
            title={title}
            quantifier={quantifier}
            subtitle={subtitle}
            features={features}
            metatags={metatags}
        />
    );
};

MerchantsUseCasePage.propTypes = {
    data: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default MerchantsUseCasePage;
export const query = graphql`
    query($language: String!, $useCaseType: String!) {
        ...UseCaseFragment
    }
`;
